body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.video-recorder__Wrapper-sc-7k20rv-0 {
    border-radius: 20px;
}

div[class^="render-action"],
div[class*=" render-action"] {
    display: none;
}

.darkBG {
    background-color: rgba(150, 140, 140, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 1010;
    top: 50%;
    backdrop-filter: blur(3px);
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.modal-custom {
    width: 500px;
    overflow: auto;
    padding: 10px;
    margin: auto;
    background: white;
    z-index: 100;
}

.centered {
    position: fixed;
    z-index: 1200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.modal-content2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.modal-content {
    margin: 38px 60px;
}

.LivechatLauncher {
    display: none !important;
}

@media only screen and (max-width: 600px) {
    .centered {
        width: 80%;
        top: 100px;
        bottom: 0;
        transform: translate(-50%, 0);
    }

    .modal-content {
        margin: 18px 30px;
    }
}
